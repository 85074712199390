<template>
  <div>
    <v-snackbar
      content-class="centered"
      absolute
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >

    <!-- <v-card class="pa-5" :elevation="0"> -->
    <v-data-table
      :headers="headers"
      :items="items"
      :search="localSearch || $store.state.search"
      :items-per-page="10"
      :no-data-text="$tc('table.no_data_text')"
      :no-results-text="$tc('table.no_data_text')"
      :loading="loading"
      class="ma-5"
      :loading-text="$tc('table.loading')"
      :sort-by="[sortBy ? sortBy : 'name']"
      :sort-desc="true"
      item-key=".key"
      fixed-header
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50, 100, 250],
      }"
    >
      <template v-slot:[`item.image`]="{ item }">
        <img
          class="image"
          @click="$emit('menuClick', { action: 'show_image', item })"
          :src="
            item.screenshot ||
            (item.image && item.image.original
              ? item.image.original
              : defaultImage)
          "
          :alt="item.name"
        />
      </template>

      <template v-slot:[`item.agencyImage`]="{ item }">
        <img
          class="image"
          @click="$emit('menuClick', { action: 'show_image', item })"
          :src="item.image ? item.image.original : defaultImage"
          :alt="item.name"
        />
      </template>

      <template v-slot:[`item.productImage`]="{ item }">
        <img
          class="image"
          @click="$emit('menuClick', { action: 'show_image', item })"
          :src="item.images.length ? item.images[0].url : defaultImage"
        />
      </template>

      <template v-slot:[`item.productSmallImage`]="{ item }">
        <img
          class="small-image"
          @click="$emit('menuClick', { action: 'show_image', item })"
          :src="item.images.length ? item.images[0].url : defaultImage"
        />
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <div class="description">
          {{ item.name }}
        </div>
      </template>

      <template v-slot:[`item.role`]="{ item }">
        <div class="description">
          {{ item.role | filterRole }}
        </div>
      </template>

      <template v-slot:[`item.amount`]="{ item }">
        {{ item.amount | currency }}
      </template>

      <template v-slot:[`item.color`]="{ item }">
        <div class="color" :style="`background-color:${item.color}`">
          {{ item.color }}
        </div>
      </template>

      <template v-slot:[`item.price`]="{ item }">
        {{ item.price | currency }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="item.status | statusColor">
          <b style="color: white"> {{ item.status | status }}</b></v-chip
        >
      </template>

      <template v-slot:[`item.show`]="{ item }">
        <v-checkbox
          hide-detail
          @click="$emit('changedVisibility', item)"
          v-model="item.show"
        ></v-checkbox>
      </template>

      <template v-slot:[`item.newPrice`]="{ item }">
        <v-text-field
          clearable
          filled
          rounded
          :placeholder="$t('headers.price')"
          type="number"
          v-model.number="item.newPrice"
          hide-details
        />
      </template>

      <template v-slot:[`item.items`]="{ item }">
        <div style="max-width: 500px; overflow-x: hidden">
          <v-chip
            class="ml-3 mt-1 mb-1"
            :key="index"
            v-for="(data, index) of item.items"
          >
            {{ data }}
          </v-chip>
        </div>
      </template>

      <template v-slot:[`item.characteristics`]="{ item }">
        <div class="characteristics">
          <span
            class="ml-3"
            :key="index"
            v-for="(data, index) of item.characteristics"
          >
            {{ data.name }} : {{ data.value }} ,
          </span>
        </div>
      </template>

      <!-- characteristics -->

      <template v-slot:[`item.extension`]="{ item }">
        <v-chip>
          {{ item.extension }}
        </v-chip>
      </template>

      <template v-slot:[`item.duration`]="{ item }">
        {{ item.duration | filterDuration }} min
      </template>

      <template v-slot:[`item.products`]="{ item }">
        {{ item.products ? item.products.length : 0 }}
      </template>

      <template v-slot:[`item.usedStorage`]="{ item }">
        {{ item.usedStorage | filterSpace }}
      </template>

      <template v-slot:[`item.totalStorage`]="{ item }">
        {{ item.totalStorage | filterSpace }}
      </template>

      <template v-slot:[`item.dataTransfer`]="{ item }">
        {{ item.dataTransfer | filterSpace }}
      </template>

      <template v-slot:[`item.dataTransferUsed`]="{ item }">
        {{ item.dataTransferUsed | filterSpace }}
      </template>

      <template v-slot:[`item.description`]="{ item }">
        <div class="description">
          {{ item.description }}
        </div>
      </template>

      <template v-slot:[`item.filesCount`]="{ item }">
        <v-chip>
          {{ item.files.length }}
        </v-chip>
      </template>

      <template v-slot:[`item.imageDuration`]="{ item }">
        {{ `${item.imageDuration} seg.` }}
      </template>

      <template v-slot:[`item.lastLogin`]="{ item }">
        {{ item.lastLogin | lastLogin }}
      </template>

      <template v-slot:[`item.visibleCategories`]="{ item }">
        {{ item.visibleCategories.length || 0 }}
      </template>

      <template v-slot:[`item.active`]="{ item }">
        <v-switch
          :loading="loading"
          @change="$emit('activeChange', item)"
          v-model="item.active"
        ></v-switch>
      </template>

      <template v-slot:[`item.playCorrectSound`]="{ item }">
        <v-switch
          :loading="loading"
          @change="$emit('correctSoundChanged', item)"
          v-model="item.playCorrectSound"
        ></v-switch>
      </template>

      <template v-slot:[`item.playWrongSound`]="{ item }">
        <v-switch
          :loading="loading"
          @change="$emit('wrongSoundChanged', item)"
          v-model="item.playWrongSound"
        ></v-switch>
      </template>

      <template v-slot:[`item.agencies`]="{ item }">
        {{ item.agencies ? item.agencies.length : 0 }}
      </template>

      <template v-slot:[`item.users`]="{ item }">
        {{ item.users ? item.users.length : 0 }}
      </template>

      <template v-slot:[`item.selectedApp`]="{ item }">
        {{ item.selectedApp | filterApp }}
      </template>

      <template v-slot:[`item.configured`]="{ item }">
        <v-chip v-if="item.selectedApp" class="ma-2"> Si </v-chip>
        <v-chip v-else class="ma-2"> No </v-chip>
      </template>

      <template v-slot:[`item.visibleSubcategories`]="{ item }">
        {{ item.visibleSubcategories.length || 0 }}
      </template>

      <template v-slot:[`item.bannerDuration`]="{ item }">
        {{ `${item.duration} seg.` }}
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        {{ item.createdAt | filterDate }}
      </template>

      <template v-slot:[`item.shortDateCreatedAt`]="{ item }">
        {{ item.createdAt | filterShortDate }}
      </template>

      <template v-slot:[`item.aspect`]="{ item }">
        {{ item.aspect | aspectRatio }}
      </template>

      <template v-slot:[`item.startDate`]="{ item }">
        {{ item.startDate | filterDate }}
      </template>

      <template v-slot:[`item.endDate`]="{ item }">
        {{ item.endDate | filterDate }}
      </template>

      <template v-slot:[`item.size`]="{ item }">
        {{ item.size | filterSize }}
      </template>

      <template v-slot:[`item.last_connectivity_event`]="{ item }">
        {{ item.last_connectivity_event | filterLastConnection }}
      </template>

      <template v-slot:[`item.is_online`]="{ item }">
        <v-chip class="white--text" :color="item.is_online ? 'green' : 'red'">
          {{ item.is_online ? "Online" : "Offline" }}
        </v-chip>
      </template>

      <template v-slot:[`item.mac_address`]="{ item }">
        <v-tooltip v-if="item.mac_address" right>
          <template v-slot:activator="{ on }">
            <v-chip
              :color="
                $vuetify.theme.dark
                  ? 'rgba(0, 0, 0, 0.4)'
                  : 'rgba(0, 0, 0, 0.1)'
              "
              style="display: inline-block"
              v-on="on"
              dark
              :text-color="$vuetify.theme.dark ? 'white' : 'black'"
              @click="copyToClipboard(item.mac_address)"
            >
              {{ item.mac_address }}
            </v-chip>
          </template>
          <i class="far fa-copy mr-2"></i>
          <span>Click para copiar</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.code`]="{ item }">
        <v-tooltip v-if="item.code" right>
          <template v-slot:activator="{ on }">
            <v-chip
              :color="
                $vuetify.theme.dark
                  ? 'rgba(0, 0, 0, 0.4)'
                  : 'rgba(0, 0, 0, 0.1)'
              "
              style="display: inline-block"
              v-on="on"
              dark
              :text-color="$vuetify.theme.dark ? 'white' : 'black'"
              @click="copyToClipboard(item.code)"
            >
              {{ item.code }}
            </v-chip>
          </template>
          <i class="far fa-copy mr-2"></i>
          <span>Click para copiar</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.ip_address`]="{ item }">
        <v-tooltip v-if="item.ip_address" right>
          <template v-slot:activator="{ on }">
            <v-chip
              :color="
                $vuetify.theme.dark
                  ? 'rgba(0, 0, 0, 0.4)'
                  : 'rgba(0, 0, 0, 0.1)'
              "
              style="display: inline-block"
              v-on="on"
              dark
              :text-color="$vuetify.theme.dark ? 'white' : 'black'"
              @click="copyToClipboard(item.ip_address)"
            >
              {{ item.ip_address }}
            </v-chip>
          </template>
          <i class="far fa-copy mr-2"></i>
          <span>Click para copiar</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.email`]="{ item }">
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-chip
              color="rgba(0, 0, 0, 0.1)"
              style="display: inline-block"
              v-on="on"
              :text-color="$vuetify.theme.dark ? 'white' : 'black'"
              @click="copyToClipboard(item.email)"
              >{{ item.email }}</v-chip
            >
          </template>
          <i class="far fa-copy mr-2"></i>
          <span>{{ $tc("table.click_to_copy") }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.options`]="{ item }">
        <v-tooltip v-if="showReport" left>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mx-1"
              fab
              v-on="on"
              :loading="loading"
              @click="$emit('menuClick', { action: 'reports', item })"
              dark
              x-small
              :elevation="0"
              color="rgba(0, 0, 0, 0.6)"
            >
              <v-icon dark>fa-chart-line</v-icon>
            </v-btn>
          </template>
          <span>Reportes</span>
        </v-tooltip>

        <v-tooltip v-if="showQuestions" left>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mx-1"
              fab
              v-on="on"
              :loading="loading"
              @click="$emit('menuClick', { action: 'questions', item })"
              dark
              x-small
              :elevation="0"
              color="#ff5900"
            >
              <v-icon dark>fa-question</v-icon>
            </v-btn>
          </template>
          <span>Ver preguntas</span>
        </v-tooltip>

        <v-tooltip v-if="showUsers" left>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mx-1"
              fab
              v-on="on"
              :loading="loading"
              @click="$emit('menuClick', { action: 'show_users', item })"
              dark
              x-small
              :elevation="0"
              color="rgba(0, 0, 0, 0.6)"
            >
              <v-icon dark>fa-users</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("tooltips.users") }}</span>
        </v-tooltip>

        <v-tooltip v-if="showAddScreens" left>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mx-1"
              fab
              v-on="on"
              :loading="loading"
              @click="$emit('menuClick', { action: 'add_screens', item })"
              dark
              x-small
              :elevation="0"
              color="rgba(0, 0, 0, 0.6)"
            >
              <v-icon dark>fa-desktop</v-icon>
            </v-btn>
          </template>
          <span>{{ $tc("table.screens") }}</span>
        </v-tooltip>

        <v-tooltip v-if="showScreens" left>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mx-1"
              fab
              v-on="on"
              :loading="loading"
              @click="$emit('menuClick', { action: 'show_screens', item })"
              dark
              x-small
              :elevation="0"
              color="rgba(0, 0, 0, 0.6)"
            >
              <v-icon dark>fa-desktop</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("tooltips.screens") }}</span>
        </v-tooltip>

        <v-tooltip v-if="showAddAgencies" left>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mx-1"
              fab
              v-on="on"
              :loading="loading"
              @click="$emit('menuClick', { action: 'add_agencies', item })"
              dark
              x-small
              :style="
                item.role != 'ed' ? 'background-color : #bbbbbb!important;' : ''
              "
              :elevation="0"
              :disabled="item.role != 'ed'"
              color="rgba(0, 0, 0, 0.6)"
            >
              <v-icon
                :style="item.role != 'ed' ? 'color : white!important;' : ''"
                >fa-building</v-icon
              >
            </v-btn>
          </template>
          <span>{{ $tc("table.assign_agencies") }}</span>
        </v-tooltip>

        <v-tooltip v-if="showInfo" left>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mx-1"
              fab
              v-on="on"
              :loading="loading"
              @click="$emit('menuClick', { action: 'screen_status', item })"
              dark
              x-small
              :elevation="0"
              color="#008BBF"
            >
              <v-icon dark>fa-info</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("tooltips.information") }}</span>
        </v-tooltip>

        <v-tooltip v-if="showView" left>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mx-1"
              fab
              v-on="on"
              @click="$emit('menuClick', { action: 'show_image', item })"
              dark
              :loading="loading"
              x-small
              :elevation="0"
              color="rgba(0, 0, 0, 0.6)"
            >
              <v-icon dark>fa-eye</v-icon>
            </v-btn>
          </template>
          <span>{{ $tc("table.view_file") }}</span>
        </v-tooltip>

        <v-tooltip v-if="showEdit" left>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mx-1"
              fab
              v-on="on"
              :loading="loading"
              @click="$emit('menuClick', { action: 'edit', item })"
              dark
              x-small
              :elevation="0"
              color="rgba(0, 0, 0, 0.6)"
            >
              <v-icon dark>fa-pencil-alt</v-icon>
            </v-btn>
          </template>
          <span>{{ $tc("table.edit") }}</span>
        </v-tooltip>

        <v-tooltip v-if="showDelete" left>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mx-1"
              fab
              :loading="loading"
              v-on="on"
              @click="$emit('menuClick', { action: 'delete', item })"
              dark
              x-small
              :elevation="0"
              color="red darken-4"
            >
              <v-icon dark>fa-trash</v-icon>
            </v-btn>
          </template>
          <span>{{ $tc("table.delete") }}</span>
        </v-tooltip>

        <v-tooltip v-if="showResetScreen" left>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mx-1"
              fab
              v-on="on"
              :loading="loading"
              @click="$emit('menuClick', { action: 'reset_screen', item })"
              dark
              x-small
              :elevation="0"
              color="red darken-4"
            >
              <v-icon dark>fa-trash-restore</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("table.clear_screen_settings") }}</span>
        </v-tooltip>

        <v-tooltip v-if="showCapture" left>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mx-1"
              fab
              v-on="on"
              :loading="loading"
              @click="$emit('menuClick', { action: 'capture', item })"
              :dark="$vuetify.theme.dark"
              :disabled="item.status != 'pending'"
              x-small
              :elevation="0"
              color="blue darken-4"
            >
              <v-icon color="#fff">fa-credit-card</v-icon>
            </v-btn>
          </template>
          <span>Capturar transacción</span>
        </v-tooltip>
      </template>

      <template v-slot:loading>
        <v-skeleton-loader
          ref="skeleton"
          type="table-tbody"
          class="mx-auto d-none d-md-block"
        >
        </v-skeleton-loader>
      </template>

      <template v-if="appendRow" slot="body.append">
        <tr class="gray--text">
          <th colspan="100%">
            <v-row class="pa-0 ma-0">
              <v-col cols="6" sm="4" md="4">
                <h2>
                  <b>
                    {{ $t("table.statistics.totalFiles") }} :
                    {{ items && items.length ? items.length : 0 }}</b
                  >
                </h2>
              </v-col>
              <v-col cols="6" sm="8" md="8">
                <h2>
                  {{ $t("table.statistics.totalSize") }} :
                  <b> {{ totalSize | filterSize }}</b>
                </h2>
              </v-col>
              <!-- <v-col cols="6" sm="4" md="4">
                <h2>
                  <b>
                    {{ $t("table.statistics.promSize") }} :
                    {{ promSize | filterSize }}</b
                  >
                </h2>
              </v-col> -->
            </v-row>
          </th>
        </tr>
      </template>
    </v-data-table>
    <!-- </v-card> -->
  </div>
</template>

<script>
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default {
  props: [
    "headers",
    "items",
    "loading",
    "showView",
    "showEdit",
    "showDelete",
    "showInfo",
    "showList",
    "showAddImage",
    "showAddAgencies",
    "showAddApps",
    "showAddScreens",
    "showResetScreen",
    "showScreens",
    "localSearch",
    "showUsers",
    "showReport",
    "appendRow",
    "sortBy",
    "showQuestions",
    "showCapture",
  ],
  name: "files-table",
  data() {
    return {
      defaultImage: require("@/assets/wink-placeholder.png"),
      snackbarText: "",
      snackbar: false,
    };
  },
  computed: {
    usedStorage() {
      if (this.group.usedStorage < 1024)
        return `${this.group.usedStorage.toFixed(2)} MB / ${
          this.group.totalStorage
        } MB`;
      else
        return `${(this.group.usedStorage / 1024).toFixed(2)} GB / ${(
          this.group.totalStorage / 1024
        ).toFixed(2)} GB`;
    },

    promSize() {
      return (
        this.items.reduce((a, b) => a + (b.size || 0), 0) / this.items.length
      );
    },

    totalSize() {
      return this.items.reduce((a, b) => a + (b.size || 0), 0);
    },
  },
  filters: {
    lastLogin(loginData) {
      let date = loginData
        ? moment(loginData.date.toDate())
            .tz("America/Tegucigalpa")
            .format("D/MM/YYYY h:mm:ss A")
        : "";
      return loginData ? `${date}( ${loginData.ip} )` : "";
    },
    filterSize(size) {
      let mb = size / 1048576;
      let gb = mb > 1024 ? mb / 1025 : 0;

      return gb ? `${gb.toFixed(2)} GB` : `${mb.toFixed(2)} MB`;
    },
    filterSpace(space) {
      return space ? `${(space / 1024).toFixed(2)} GB` : "0 GB";
    },

    statusColor(e) {
      let status = {
        pending: "#1367aa",
        captured: "green",
        reversed: "#e03333",
      };

      return status[e] ?? "black";
    },

    status(e) {
      let status = {
        pending: "Captura pendiente",
        captured: "Capturada",
        reversed: "Reversada",
      };

      return status[e] ?? "Desconocido";
    },
    filterApp(item) {
      const apps = {
        player: "Contenido Multimedia",
        zones: "Multizonas",
        directories: "Directorios Táctiles",
        trivia: "Trivia Interactiva",
        roulette: "Ruleta Interactiva",
      };
      return item ? apps[item] : "Sin configuración";
    },
    filterRole(code) {
      let roles = {
        sa: "Super usuario",
        su: "Supervisor",
        ed: "Editor",
        de: "Editor de Directorios",
      };
      return code ? roles[code] : "";
    },
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(amount);
    },

    aspectRatio(index) {
      let aspect = ["4:3", "5:4", "16:9", "16:10"];
      return aspect[index];
    },

    filterDate(timestamp) {
      if (!timestamp) return "";
      return moment(timestamp.toDate()).tz("America/Tegucigalpa").format("LL");
    },
    filterShortDate(timestamp) {
      if (!timestamp) return "";
      return moment(timestamp.toDate()).tz("America/Tegucigalpa").format("L");
    },

    filterDuration(duration) {
      let result = 0;
      if (duration) {
        let minutes = parseInt(duration / 60);
        let seconds = parseInt(duration % 60);

        result = `${minutes}:${seconds}`;
      }

      return result;
    },

    filterLastConnection(date) {
      return moment(date).fromNow(); //format("DD MMMM YYYY, h:mm A");
    },
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.snackbar = true;
        this.snackbarText = this.$tc("table.copied_to_clipboard");
      });
    },
  },
};
</script>

<style scoped>
.name {
  background: linear-gradient(to right, #00000079, #00000027, #0000000a);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ellipsis {
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 0;
  color: white;
  font-weight: bold;
  font-size: 25px;
  width: 50px;
  height: 50px;
  text-align: center;
}

.v-card > :first-child:not(.v-btn):not(.v-chip),
.v-card > .v-card__progress + :not(.v-btn):not(.v-chip) {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  -webkit-box-shadow: 3px 6px 15px 1px#494949;
  box-shadow: 3px 6px 15px 1px#494949;
}

.image {
  object-fit: cover;
  cursor: pointer;
  max-width: 120px;
  min-width: 120px;
  max-height: 80px;
  min-height: 80px;
  margin-top: 5px;
}

.small-image {
  object-fit: cover;
  cursor: pointer;
  max-width: 60px;
  min-width: 60px;
  max-height: 40px;
  min-height: 40px;
  margin-top: 5px;
}

.description {
  white-space: nowrap;
  max-width: 200px;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.characteristics {
  white-space: nowrap;
  max-width: 450px;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.color {
  padding: 5px;
  color: white;
  text-align: center;
  border-radius: 5px;
  font-weight: bold;
}
</style>